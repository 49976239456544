import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const HomeLink = styled(Link)`
  color: #fff;
  font-weight: 'normal';
  margin: 0;
  text-decoration: none;
  background-image: none;
`;

export default HomeLink;
