import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TemporaryDrawer from './temporary-drawer';
import HomeLink from './homelink';
import Icon from '../svg/MasumiHayashiFineArt.svg';
import './material-app-bar.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
  },
  appBar: {
    width: '100vw',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
  },

  title: {
    // [theme.breakpoints.down('sm')]:{
    //   fontSize: '1.2rem',
    // },
    // [theme.breakpoints.up('sm')]:{
    //   fontSize: '1.4rem',
    // },
    // [theme.breakpoints.up('md')]:{
    //   fontSize: '1.6rem',
    // },
    // [theme.breakpoints.up('lg')]:{
    //   fontSize: '2rem'
    // }
  },
  fineArt: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative">
        <Toolbar className={classes.toolbar}>
          <HomeLink to="/" activeClassName="current-page" fontWeight="bold">
            <Icon className="logo" />
          </HomeLink>
          <TemporaryDrawer className={classes.menu} />
        </Toolbar>
      </AppBar>
    </div>
  );
}
